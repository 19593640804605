/* global ga, smoothScroll, Ten4Slideshow, TimeMachine, VideoPlayer, sendTrackingEvent */

import objectFitImages from './vendor/polyfills/object-fit.min.js';
import './vendor/polyfills/classlist.min.js';
import './vendor/polyfills/eventlistener.min.js';
import './vendor/polyfills/svgxuse.min.js';

import './vendor/event-tracker.min.js';
import './vendor/ajax.min.js';
import imagesLoaded from 'imagesloaded';
import Masonry from 'masonry-layout';
import Siema from './vendor/siema.min.js';
import './vendor/smoothscroll.min.js';
import './vendor/ten4-slideshow.js';

import LoadOMatic from './vendor/Loadomatic.js';
import './vendor/VideoPlayer.js';
import './vendor/TimeMachine.js';
import Uncloak from './vendor/Uncloak.js';

( () => {
	window.supportsPassive = false;
	try {
		window.addEventListener( 'test', null, {
			get passive() {
				window.supportsPassive = true;
			}
		} );
	} catch ( e ) {
		//
	}
} )();

( () => {
	// Cookie notice
	if ( document.cookie.indexOf( 'seenCookieNotice' ) === -1 ) {
		document.cookie = 'seenCookieNotice=yes;path=/;max-age=31536000';
		document.body.classList.add( 'show-cookie-notice' );
		document.querySelector( '.cookie-notice button' ).addEventListener( 'click', () => {
			document.body.classList.remove( 'show-cookie-notice' );
		} );
	}
} )();

( () => {
	// Custom GA link tracking
	const linksToTrack = document.querySelectorAll( '[data-track-category]' );

	for ( let k = 0; k < linksToTrack.length; k += 1 ) {
		linksToTrack[k].addEventListener( 'click', sendTrackingEvent( linksToTrack[k] ) );
	}
} )();

( () => {
	// Custom GA text copy tracking

	function getSelectionText() {
		var text = '';
		if ( window.getSelection ) {
			text = window.getSelection().toString();
		} else if ( document.selection && document.selection.type !== 'Control' ) {
			text = document.selection.createRange().text;
		}
		return text;
	}

	document.addEventListener( 'copy', () => {
		if ( typeof ga !== 'undefined' ) {
			ga( 'send', {
				hitType: 'event',
				eventCategory: 'Text Copied - ' + location.pathname,
				eventAction: 'copy',
				eventLabel: getSelectionText()
			} );
		}
	} );
} )();

( () => {
	// Header
	const header = document.querySelector( '.header' );
	const trigger = header.querySelector( '.header__trigger' );
	const dy = 5;
	let y0 = 0;

	trigger.addEventListener( 'click', () => {
		document.body.classList.toggle( 'nav-open' );
	} );
	window.addEventListener( 'keyup', ( e ) => {
		if ( document.body.classList.contains( 'nav-open' ) && e.keyCode === 27 ) {
			document.body.classList.remove( 'nav-open' );
		}
	} );
	window.addEventListener( 'popstate', () => {
		handleClasses( getScrollY() );
	} );
	window.addEventListener( 'scroll', () => {
		handleScroll();
	}, false );
	window.addEventListener( 'resize', () => {
		handleClasses( getScrollY() );
	} );

	function handleClasses( y ) {
		if ( y > 200 ) {
			header.classList.remove( 'header--top' );
			header.classList.add( 'header--scrolling' );
			y > y0 ? header.classList.add( 'header--hidden' ) : header.classList.remove( 'header--hidden' );
		} else {
			header.classList.remove( 'header--scrolling' );
			header.classList.add( 'header--top' );
		}
		y0 = y;
	}
	function handleScroll() {
		const y = getScrollY();

		if ( Math.abs( y0 - y ) <= dy ) return;
		handleClasses( y );
	}

} )();

( () => {
	const tm_ids = {
		default: 'tm-receptacle',
		overlay: 'tm-overlay'
	};
	const current_state = {
		lastFullUrl: window.location.href,
		container: 'tm-receptacle'
	};
	// const default_container = document.getElementById( tm_ids.default );
	const header = document.querySelector( '.header' );
	const banner = document.querySelector( '.banner' );
	const page_render_delay = 300;
	const nav_items = document.querySelectorAll( '[data-tm-match]' );
	const nav_active_class = 'nav__item--active';

	const tm_overlay = buildOverlay( tm_ids.overlay );

	new TimeMachine( {
		default_ajax_receptacle_id: tm_ids.default,
		debug: false,
		beforeNewPageLoad: hookBeforeNewPageLoad,
		beforeNewPageRender: hookBeforeNewPageRender,
		afterNewPageLoad: hookAfterNewPageLoad
	} );

	function hookBeforeNewPageLoad( updated_receptacle_id, source_id, loadPage ) {
		const new_state = {
			container: updated_receptacle_id,
			url: window.location.href
		};
		if ( current_state.container === tm_ids.overlay ) {
			if ( new_state.container === tm_ids.default ) {
				current_state.container = new_state.container;
				destroyOverlay();
			}
			if ( new_state.url === current_state.lastFullUrl ) {
				return;
			}
		}
		if ( new_state.url === current_state.lastFullUrl && new_state.url.indexOf( '#' ) > -1 ) {
			scrollToEl( new_state.url );
			return;
		}
		current_state.container = new_state.container;
		if ( updated_receptacle_id === tm_ids.overlay ) {
			beforeOverlayLoad();
			loadPage();
			return;
		}
		current_state.lastFullUrl = new_state.url;
		beforeHardLoad();
		loadPage();
	}
	function beforeHardLoad() {
		destroyOverlay();
		document.body.classList.add( 'page-loading' );
		document.body.classList.remove( 'gallery-open' );
		destroyPageScript();

		const old_banner_inner = banner.querySelector( '.banner__inner' );
		if ( old_banner_inner ) {
			old_banner_inner.classList.add( 'banner__inner--old' );
		}

		// wait for out animations to complete before loading new page
		if ( isMinWidth( 1000 ) ) {
			setTimeout( () => {
				resetHeader();
			}, 420 );
		}
	}
	function beforeOverlayLoad() {
		openOverlay();
		destroyPageScript( true );
	}

	// BEFORE NEW PAGE RENDER
	function hookBeforeNewPageRender( container, new_html, render ) {
		if ( container.id === tm_ids.default ) {
			const new_banner_inner = new_html.querySelector( '.banner__inner' );
			if ( !new_banner_inner ) {
				banner.classList.add( 'banner--hidden' );
			} else {
				if ( banner.getBoundingClientRect().top < 0 ) {
					banner.classList.add( 'banner--short-switch' );
				}
				banner.classList.remove( 'banner--hidden' );
				new_banner_inner.classList.add( 'banner__inner--hidden' );
				banner.appendChild( new_banner_inner );
				prepVideo( banner, false );
			}
		}

		setTimeout( () => {
			render();
		}, page_render_delay );
	}

	// AFTER NEW PAGE LOAD
	function hookAfterNewPageLoad( updated_receptacle_id ) {
		const el = document.getElementById( updated_receptacle_id ).firstElementChild;
		if ( typeof ga !== 'undefined' ) {
			ga( 'send', 'pageview' );
		}
		if ( !isMinWidth( 1000 ) ) {
			resetHeader();
		}
		if ( updated_receptacle_id === tm_ids.overlay ) {
			afterOverlayLoad();
			return;
		}
		afterHardLoad( el );
	}
	function afterHardLoad( metadata_el ) {
		const new_url = window.location.href;
		if ( new_url.indexOf( '#' ) > 0 ) {
			scrollToEl( new_url );
		} else {
			window.scrollTo( 0, 0 );
		}
		highlightNavItem( metadata_el.getAttribute( 'data-tm-id' ) );
		updateClasses( metadata_el );
		window.runPageScript();

		// const header_image = default_container.querySelector( '[data-tm-header-image]' );
		// if ( header_image ) {
		// 	imagesLoaded( header_image, () => {
		// 		document.body.classList.remove( 'page-loading' );
		// 	} );
		// 	return;
		// }
		const new_banner_inner = banner.querySelector( '.banner__inner--hidden' );
		const old_banner_inner = banner.querySelector( '.banner__inner--old' );
		if ( new_banner_inner ) {
			if ( window.videoPlayers ) {
				for ( let i = 0; i < window.videoPlayers.length; i++ ) {
					window.videoPlayers[i].play();
				}
			}
			new_banner_inner.classList.remove( 'banner__inner--hidden' );
		}
		document.body.classList.remove( 'page-loading' );
		setTimeout( () => {
			banner.classList.remove( 'banner--short-switch' );
			if ( old_banner_inner ) {
				banner.removeChild( old_banner_inner );
			}
		}, 400 );
	}
	function afterOverlayLoad() {
		window.runPageScript( true );
		imagesLoaded( tm_overlay.content, () => {
			document.body.classList.remove( 'lazy-loading' );
			tm_overlay.content.classList.remove( 'overlay__content--hidden' );
		} );
	}

	// highlight nav item
	function highlightNavItem( new_id ) {
		const active_ids = buildNewPageIds( new_id );
		for ( let i = 0; i < nav_items.length; i++ ) {
			checkMatch( active_ids, nav_items[i] );
		}
		function checkMatch( page_ids, nav_item ) {
			const match_id = nav_item.getAttribute( 'data-tm-match' );
			let matched = false;
			for ( let i = 0; i < page_ids.length; i++ ) {
				if ( match_id === page_ids[i] ) {
					nav_item.classList.add( nav_active_class );
					matched = true;
				}
			}
			if ( !matched ) {
				nav_item.classList.remove( nav_active_class );
			}
		}
		function buildNewPageIds( page_id ) {
			const page_id_arr = [ page_id ];
			while ( page_id.indexOf( '/' ) !== -1 ) {
				page_id = page_id.slice( 0, page_id.lastIndexOf( '/' ) );
				page_id_arr.push( page_id );
			}
			return page_id_arr;
		}
	}

	// other helpers
	function buildOverlay( id ) {
		const el = document.getElementById( id );
		const parent = el.parentNode;
		const obj = {
			container: parent,
			content: el,
			close: parent.querySelector( '.overlay__close' )
		};

		obj.close.addEventListener( 'click', () => {
			window.history.back();
		} );
		document.addEventListener( 'keyup', ( e ) => {
			if ( e.keyCode === 27 && document.body.classList.contains( 'lazy-open' ) ) {
				window.history.back();
			}
		} );

		return obj;
	}
	function destroyPageScript( load_type = 'default' ) {
		if ( load_type !== 'default' ) {
			window.Pozzoni = null;
			window.PozzoniAuto = null;
		}
		window.PozzoniCustom = null;
	}
	function resetHeader() {
		document.body.classList.remove( 'nav-open' );
		header.classList.remove( 'header--hidden', 'header--scrolling' );
		header.classList.add( 'header--top' );
	}
	function scrollToEl( url ) {
		const target_full = url.split( '#' );
		const target_id = target_full[1] || '';
		if ( target_id.length === 0 ) return;

		const target = document.getElementById( target_id );
		if ( !target ) return;

		let dy = target.getBoundingClientRect().top;
		if ( dy < 0 ) dy = dy - 103; // to account for the sticky header
		smoothScroll.scroll( dy + getScrollY(), 400 );
	}
	function toggleClass( el, str, classname ) {
		if ( str.indexOf( classname ) === -1 ) {
			el.classList.remove( classname );
			return;
		}
		el.classList.add( classname );
	}
	function updateClasses( metadata_element ) {
		const header_class = metadata_element.getAttribute( 'data-tm-header' );
		const classes = [ 'header--dark', 'header--show-subnav' ];
		for ( let i = 0; i < classes.length; i++ ) {
			toggleClass( header, header_class, classes[i] );
		}
	}

	// overlay helpers
	function openOverlay() {
		document.body.classList.add( 'lazy-open', 'lazy-loading' );
	}
	function destroyOverlay() {
		document.body.classList.remove( 'lazy-open' );
		tm_overlay.content.classList.add( 'overlay__content--hidden' );
		tm_overlay.content.innerHTML = '';
	}
} )();

window.runPageScript = () => {
	window.PozzoniAuto = () => {
		applyTouchListener();
		buildDropdowns();
		calciOSViewportHeights();
		hookVideoEmbeds();
		// polyfillObjectFit();
		uncloakItems();
	};
	window.PozzoniAuto();

	if ( typeof window.Pozzoni === 'function' ) {
		window.Pozzoni( {
			assembleLoadOMatic: assembleLoadOMatic,
			buildGallery: buildGallery,
			buildHeaderSlideshow: buildHeaderSlideshow,
			buildMasonryGrids: buildMasonryGrids,
			setupSmoothScrolls: setupSmoothScrolls
		} );
	}
};
window.runPageScript();
window.videoPlayers = [];
prepVideo( document, true );

function applyTouchListener() {
	document.addEventListener( 'touchstart', () => {}, window.supportsPassive ? { passive: true } : false );
}
function assembleLoadOMatic( options ) {
	const container = document.querySelector( '.loadomatic' );
	if ( !container ) return;

	const loadomatic = new LoadOMatic( options );

	loadomatic.trigger.addEventListener( 'click', () => {
		loadomatic.loadMore();
	} );

	loadomatic.addCallback( () => {
		const new_items = document.querySelectorAll( '[data-uncloak-new]' );
		loadomatic.handle_trigger_dly = ( new_items.length - 0.9 ) * 250;
		window.uncloakInstance.findNewItems( new_items );
	} );
}

function buildDropdowns() {
	// Dropdowns
	const els = document.querySelectorAll( '.dropdown' );
	if ( !els[0] ) {
		return;
	}

	for ( let i = 0; i < els.length; i++ ) {
		buildDropdown( els[i] );
	}

	function buildDropdown( el ) {
		el.addEventListener( 'click', () => {
			el.classList.toggle( 'dropdown--open' );
		} );
	}
}

function buildGallery() {
	const containers = document.querySelectorAll( '.gallery' );
	if ( !containers[0] ) return;

	for ( let i = 0; i < containers.length; i++ ) {
		initGallery( containers[i] );
	}

	function initGallery( container ) {
		if ( container.hasAttribute( 'data-gallery-ready' ) ) return;

		const slider = container.querySelector( '.gallery__container' );
		const slider_counter = container.querySelector( '.gallery__counter' );
		const counter_current = slider_counter.querySelector( '[data-gallery-index]' );
		const counter_total = slider_counter.querySelector( '[data-gallery-total]' );
		const project_link_container = container.querySelector( '.gallery__project' );
		let slides = container.querySelectorAll( '.gallery__slide' );
		const base_slide = slides[0];
		let siema = null;
		let links = [];

		if ( container.hasAttribute( 'data-gallery-find-items' ) ) {
			slides = [];
			links = document.querySelectorAll( '.lightbox' );
			if ( !links.length ) return;
			buildSlides( slider, links );
		}

		loadSlide( 0 );
		updateProjectUrl( 0 );
		if ( slides.length > 1 ) {
			// load final slide for siema to copy
			loadSlide( slides.length - 1 );

			siema = new Siema( {
				selector: slider,
				draggable: true,
				duration: 300,
				easing: 'ease-out',
				loop: true,
				perPage: 1,
				startIndex: 0,
				threshold: 20,
				onInit: () => {
					if ( !container.hasAttribute( 'data-lightbox' ) ) {
						base_slide.classList.remove( 'gallery__slide--hidden' );
					}
				},
				onChange: () => {
					loadSlide( siema.currentSlide );
					updateCounter( siema.currentSlide );
					updateProjectUrl( siema.currentSlide );
				},
			} );

			const next = container.querySelector( '.gallery__btn--next' );
			next.classList.remove( 'gallery__btn--disabled' );
			const prev = container.querySelector( '.gallery__btn--prev' );
			prev.classList.remove( 'gallery__btn--disabled' );
			if ( prev ) prev.addEventListener( 'click', () => {
				siema.prev();
			}, false );
			if ( next ) next.addEventListener( 'click', () => {
				siema.next();
			}, false );
		}

		const close = container.querySelector( '.gallery__btn--close' );
		if ( close ) close.addEventListener( 'click', closeGallery, false );

		document.addEventListener( 'keyup', ( e ) => {
			if ( !document.body.classList.contains( 'gallery-open' ) ) return;
			if ( e.keyCode === 27 ) {
				closeGallery();
				return;
			}
			if ( siema ) {
				if ( e.keyCode === 37 ) siema.prev();
				else if ( e.keyCode === 39 ) siema.next();
			}
		}, false );

		if ( links ) {
			for ( let i = 0; i < links.length; i++ ) {
				links[i].addEventListener( 'click', handleLightboxLinkClick( i ), false );
			}
		}
		container.setAttribute( 'data-gallery-ready', '' );

		// helpers
		function buildSlide( link ) {
			// NB clone node not quick enough for IE
			const slide = document.createElement( 'DIV' );
			const project_link = link.getAttribute( 'data-project-url' );
			slide.className = 'gallery__slide gallery__slide--hidden';
			slide.innerHTML = '<div class="gallery__inner"></div>';
			if ( project_link ) {
				slide.setAttribute( 'data-project-url', project_link );
			}
			const slide_inner = slide.querySelector( '.gallery__inner' );
			let obj;
			let slide_type;

			if ( link.getAttribute( 'data-gallery-type' ) === 'img' ) {
				obj = document.createElement( 'IMG' );
				obj.className = 'gallery__img';
				obj.setAttribute( 'data-src', link.href );
				slide_type = 'img';
			}

			if ( link.getAttribute( 'data-gallery-type' ) === 'video' ) {
				obj = document.createElement( 'IFRAME' );
				obj.src = link.href;
				obj.width = 1920;
				obj.height = 1080;
				obj.className = 'gallery__video';
				obj.setAttribute( 'frameborder', '0' );
				slide_type = 'video';
			}

			slide.setAttribute( 'data-slide-type', slide_type );
			slide_inner.appendChild( obj );

			if ( link.hasAttribute( 'data-gallery-caption' ) ) {
				const caption = document.createElement( 'p' );
				caption.className = 'gallery__caption';
				caption.innerHTML = link.getAttribute( 'data-gallery-caption' );
				slide.appendChild( caption );
			}

			slider.appendChild( slide );
			slides.push( slide );
		}
		function buildSlides( slider, els ) {
			slider.innerHTML = '';
			counter_total.innerHTML = els.length;
			for ( let i = 0; i < els.length; i++ ) {
				buildSlide( els[i] );
			}
		}
		function closeGallery() {
			document.body.classList.remove( 'gallery-open' );
		}
		function handleLightboxLinkClick( i ) {
			return ( e ) => {
				e.preventDefault();
				if ( siema ) {
					siema.goTo( i );
				}
				openGallery();
			};
		}
		function loadSlide( index ) {
			const slide = slides[index];
			if ( !slide.hasAttribute( 'data-slide-ready' ) && slide.getAttribute( 'data-slide-type' ) === 'img' ) {
				const img = slide.querySelector( 'img' );
				img.src = img.getAttribute( 'data-src' );
				img.removeAttribute( 'data-src' );
				slide.setAttribute( 'data-slide-ready', '' );
			}
			slide.classList.remove( 'gallery__slide--hidden' );
		}
		function openGallery() {
			document.body.classList.add( 'gallery-open' );
		}
		function updateCounter( index ) {
			counter_current.innerHTML = ( index + 1 );
		}
		function updateProjectUrl( index ) {
			const project_link = project_link_container.firstElementChild;
			const new_link = slides[index].getAttribute( 'data-project-url' );
			if ( new_link ) {
				project_link.href = new_link;
				project_link_container.classList.remove( 'hidden' );
				return;
			}
			project_link.href = '';
			project_link_container.classList.add( 'hidden' );

		}
	}
}

function buildHeaderSlideshow() {
	const el = document.querySelector( '.slideshow' );
	if ( !el ) return;

	const slideshow = new Ten4Slideshow( el );
	const slide_interval = parseInt( el.getAttribute( 'data-slide-interval' ), 10 );

	const timer = el.querySelector( '.timer' );
	const timer_circles = timer.querySelectorAll( '[data-timer-half]' );
	let timer_id = null;

	const slides = el.querySelectorAll( '.slideshow__item' );
	const transition_time = 400;
	const slide_data = [];

	setupSlides( slides );
	init();

	slideshow.addCallback( ( index ) => {
		const current_slide = slide_data[index];
		if ( current_slide.type === 'video' && !current_slide.item.isDisabled() && ( getWindowWidth() >= 699 ) ) {
			clearInterval();
			resetTimer( slide_data[index].timer );
			current_slide.item.play();
		} else {
			resetInterval();
		}
		resetTimer( slide_data[index].timer );

		const prev_slide = slide_data[slideshow.top_slide_i];
		if ( prev_slide.type === 'video' && !prev_slide.item.isDisabled() ) {
			setTimeout( () => {
				prev_slide.item.reset();
			}, 50 );
		}
	} );

	function clearInterval() {
		window.clearInterval( timer_id );
	}
	function resetInterval() {
		clearInterval();
		resetTimer( slide_interval );
		timer_id = window.setInterval( () => {
			slideshow.slide();
		}, slide_interval );
	}
	function resetTimer( time ) {
		timer.classList.remove( 'timer--anim' );
		const timer_dur = ( ( time + transition_time ) / 2 ) + 'ms';
		for ( let i = 0; i < timer_circles.length; i++ ) {
			const x = timer_circles[i];
			x.style.animationDuration = timer_dur;
			if ( x.classList.contains( 'timer__half__inner' ) ) {
				x.style.animationDelay = timer_dur;
			}
		}
		void document.body.offsetWidth; // Trigger reflow to restart animation on class add.
		setTimeout( () => {
			timer.classList.add( 'timer--anim' );
		}, 1 );
	}

	function setupSlides( els ) {
		for ( let i = 0; i < els.length; i++ ) {
			slide_data.push( createSlideObject( els[i], i ) );
		}
	}
	function createSlideObject( el, index ) {
		if ( el.getAttribute( 'data-slide-type' ) === 'video' ) {
			const video = el.querySelector( 'video' );
			const player = new VideoPlayer( { container: el, video: video, autoplay: index === 0 }, true );
			player.video.addEventListener( 'ended', () => {
				slideshow.slide();
			}, false );
			player.addCallback( 'error', () => {
				resetInterval();
			} );
			player.addCallback( 'durationchange', () => {
				slide_data[index].timer = player.video.duration * 1000;
			} );
			return { item: player, type: 'video', timer: slide_interval };
		}
		return { item: null, type: 'image', timer: slide_interval };
	}
	function init() {
		const playReveal = () => {
			resetTimer( slide_data[0].timer );
			slide_data[0].item.video.removeEventListener( 'playing', playReveal, false );
		};

		if ( slide_data[0].type === 'video' && isMinWidth( 699 ) ) {
			slide_data[0].item.video.addEventListener( 'playing', playReveal, false );
			return;
		}
		resetInterval();
	}
}

function buildMasonryGrids() {
	const els = document.querySelectorAll( '[data-msnry]' );
	if ( !els[0] ) return;

	for ( let i = 0; i < els.length; i++ ) {
		init( els[i] );
	}

	function init( el ) {
		imagesLoaded( el, () => {
			new Masonry( el, {
				columnWidth: '[data-msnry-sizer]',
				itemSelector: '[data-msnry-item]',
				percentPosition: true,
				transitionDuration: 0
			} );
		} );
	}
}

function calciOSViewportHeights() {
	// fix 100vh variable height on ios
	const els = document.querySelectorAll( '[data-calc-ios-vh]' );
	if ( !els[0] ) return;

	const user_agent = window.navigator.userAgent.toLowerCase();
	const ios = /iphone|ipod|ipad/.test( user_agent );
	if ( !ios ) return;

	const updateHeights = () => {
		const y0 = getWindowHeight();
		for ( let i = 0; i < els.length; i++ ) setiOSHeight( els[i], y0 );
	};

	updateHeights();
	window.addEventListener( 'orientationchange', updateHeights, false );

	function setiOSHeight( el, base_height ) {
		el.style.height = ( ( base_height * parseInt( el.getAttribute( 'data-calc-ios-vh' ) ) / 100 ) - 40 ) + 'px';
	}
}

function hookVideoEmbeds() {
	const els = document.querySelectorAll( '[data-video-embed]' );
	if ( !els[0] ) return;

	for ( let i = 0; i < els.length; i++ ) {
		addClickHandler( els[i] );
	}

	function addClickHandler( el ) {
		const poster = el.querySelector( '.video__placeholder' );
		if ( !poster ) return;

		const iframe = el.querySelector( 'iframe' );
		poster.addEventListener( 'click', () => {
			iframe.src = iframe.getAttribute( 'data-video-src' );
			el.classList.add( 'video-loaded' );
		} );

	}
}

function polyfillObjectFit() {
	const items = document.querySelectorAll( '.lazy--cover img' );
	if ( !items[0] ) {
		return;
	}

	objectFitImages( items );	
}

function prepVideo( container = document, autoplay ) {
	// Banner Image
	const el = container.querySelector( '.banner__video' );
	if ( !el ) {
		return;
	}
	const video_el = el.querySelector( 'video' );
	window.videoPlayers = [new VideoPlayer( { container: el, video: video_el, autoplay: autoplay }, true )];
	video_el.addEventListener( 'ended', () => {
		el.classList.remove( 'video-loaded' );
	} );
}

function setupSmoothScrolls() {
	const links = document.querySelectorAll( '[data-scroll-target]' );
	if ( !links[0] ) return;

	for ( let i = 0; i < links.length; i++ ) {
		hookLink( links[i] );
	}

	function hookLink( el ) {
		const target = document.querySelector( el.getAttribute( 'data-scroll-target' ) );
		el.addEventListener( 'click', () => {
			smoothScroll.scroll( target.getBoundingClientRect().top + getScrollY(), 400 );
		} );
	}
}

function uncloakItems() {
	window.uncloakInstance = new Uncloak( {
		progVars: { m: 200, c: 80 }
	} );
}


// Helpers
function getScrollY() {
	return ( window.scrollY || document.documentElement.scrollTop );
}
function getWindowHeight() {
	return ( window.innerHeight || document.documentElement.clientHeight );
}
function getWindowWidth() {
	return ( window.innerWidth || document.documentElement.clientWidth );
}
function isMinWidth( min ) {
	return ( getWindowWidth() >= min );
}
