// req VideoPlayer
// nb reveal delay does not work with play reveal hook

export default class Uncloak {
	constructor( options ) {
		this.items = [];
		this.progVars = options.progVars || { m: 80, c: 100 }; // form: y = mx + c
		this.callbacks = options.callbacks || { create: [], process: [] };

		// initial load
		this.init();
	}

	init() {
		this.findNewItems();
		let is_ready = true;

		const processItems = () => {
			let base_delay = 0;
			for ( let i = 0; i < this.items.length; i++ ) {
				base_delay = this.processItem( this.items[i], base_delay );
			}
			return true;
		};

		const fps = 60;
		let y0 = getScrollY();
		let t0 = performance.now();

		requestAnimationFrame( update );
		window.addEventListener( 'resize', processItems );

		function update() {
			requestAnimationFrame( update );
			const t1 = performance.now();
			if ( !is_ready || ( t1 - t0 ) < fps ) {
				return;
			}

			const dy = getScrollY();
			if ( dy === y0 ) {
				return;
			}
			is_ready = false;

			y0 = dy;
			t0 = t1;
			is_ready = processItems();
			requestAnimationFrame( update );
		}
		function getScrollY() {
			return ( window.scrollY || document.documentElement.scrollTop );
		}
	}

	findNewItems( raw_elements = document.querySelectorAll( '[data-uncloak-new]' ) ) {
		if ( !raw_elements.length ) return;

		const offset = this.items.length;
		let base_delay = 0;
		for ( let i = 0; i < raw_elements.length; i++ ) {
			this.createItem( raw_elements[i] );
			base_delay = this.processItem( this.items[i + offset], base_delay );
		}
	}

	createItem( raw_element ) {
		raw_element.removeAttribute( 'data-uncloak-new' );
		const item = {
			delayType: raw_element.getAttribute( 'data-uncloak-delay-type' ) || null,
			el: raw_element,
			hasVideo: raw_element.hasAttribute( 'data-uncloak-video' ),
			lazyContent: raw_element.querySelectorAll( '[data-lazy-src], [data-lazy-srcset]' ),
			lazyContentLoaded: false,
			offsetFraction: raw_element.getAttribute( 'data-uncloak-offset' ) || 1,
			promise: null,
			delayTimer: {
				y0: 0,
				y1: null
			},
			videoPlayer: null
		};

		if ( item.hasVideo ) {
			const container = raw_element.querySelector( '.' + raw_element.getAttribute( 'data-uncloak-video' ) );
			item.videoPlayer = new VideoPlayer( { container: container, autoplay: 0 }, false );
			item.videoPlayer.addCallback( 'firstPlay', () => {
				this.uncloak( item, 0 );
			} );
			item.videoPlayer.addCallback( 'error', () => {
				if ( item.lazyContent.length > 0 ) {
					this.handleLazyContent( item, 0 );
					return;
				}
				this.uncloak( item, 0 );
			} );
		}

		this.runCallbacks( item, 'create' );
		this.items.push( item );
	}

	processItem( item, base_delay ) {
		const element = item.el;
		let dy, dy_top, dy_bot;
		let delay = 0;
		try {
			dy = element.getBoundingClientRect();
			dy_top = dy.top;
			dy_bot = dy.bottom;
		} catch ( e ) {
			dy_top = 0;
			dy_bot = 0;
		}
		const in_bounds = this.inBounds( dy_top, item.offsetFraction );
		const cloaked = element.classList.contains( 'uncloak--cloaked' );

		if ( in_bounds && cloaked ) {
			// item in bounds, calculate delay
			if ( item.delayType !== null && this.inTopBounds( dy_bot, 0 ) && item.delayTimer.y1 === null ) {
				delay = this.createDelayTimeout( base_delay, item.delayType );
				item.delayTimer = {
					y0: performance.now(),
					y1: delay
				};
				base_delay++;
			}
		}

		this.loadItem( item, { top: dy_top, bot: dy_bot } );

		if ( in_bounds && this.imagesLoaded( item ) ) {
			this.uncloak( item );
		}

		return base_delay;
	}

	loadItem( item, rect ) {
		if ( item.hasVideo && !item.videoPlayer.isDisabled() ) {
			this.handleVideo( item, this.inBounds( rect.top, 1.25, rect.bot ) );
			return;
		}
		// Only handle lazy content if video is not working
		if ( item.lazyContent.length > 0 && this.inBounds( rect.top, 2, rect.bot ) && !item.lazyContentLoaded ) {
			this.handleLazyContent( item );
		}
	}

	uncloak( item ) {
		const el = item.el;
		// if ( !item.hasVideo ) {
		// 	const i = this.items.indexOf( item );
		// 	this.items.splice( i, 1 );
		// }
		const dy = ( performance.now() - item.delayTimer.y0 );
		const final_delay = item.delayTimer.y1 - dy;
		if ( final_delay <= 0 ) {
			el.classList.remove( 'uncloak--cloaked' );
			return;
		}
		setTimeout( () => {
			el.classList.remove( 'uncloak--cloaked' );
		}, final_delay );
	}

	// helpers
	createDelayTimeout( delay_factor, delay_type ) {
		switch ( delay_type ) {
			case 'sequential':
				return ( delay_factor * this.progVars.m + this.progVars.c );
				break;

			case 'random':
				return ( 750 * Math.random() );
				break;

			default:
				return delay_factor * 250;
		}
	}
	handleLazyContent( item ) {
		const els = item.lazyContent;
		let left_to_load = els.length;

		for ( let i = 0; i < els.length; i++ ) {
			let el = els[i];
			const lazy_srcset = el.getAttribute( 'data-lazy-srcset' ) || null;
			if ( lazy_srcset ) {
				el.srcset = lazy_srcset;
			}
			el.src = el.getAttribute( 'data-lazy-src' );
			const loaded = () => {
				el.removeEventListener( 'load', loaded, false );
				el.removeAttribute( 'data-lazy-src' );
				if ( lazy_srcset ) el.removeAttribute( 'data-lazy-srcset' );
				left_to_load -= 1;
				if ( left_to_load === 0 ) {
					item.lazyContentLoaded = true;
					if ( this.inBounds( item.el.getBoundingClientRect().top, item.offsetFraction ) ) this.uncloak( item );
				}
			};
			el.addEventListener( 'load', loaded, false );
		}
	}
	handleVideo( item, in_bounds ) {
		if ( in_bounds ) {
			item.videoPlayer.play();
			return;
		}
		item.videoPlayer.pause();
	}
	getVH( frac = 1 ) {
		return ( window.innerHeight || document.documentElement.clientHeight ) * frac;
	}
	imagesLoaded( el ) {
		return ( el.lazyContent.length === 0 || el.lazyContentLoaded );
	}
	inBounds( ytop, fraction, ybot = 0 ) {
		return ( ytop < this.getVH( fraction ) || ( ybot > 0 ? this.inTopBounds( ybot, 1 - fraction ) : false ) );
	}
	inTopBounds( ybot, fraction ) {
		return ( ybot > this.getVH( fraction ) );
	}
	runCallbacks( item, type ) {
		const cb = this.callbacks[type];
		if ( !cb.length ) return;
		for ( let i = 0; i < cb.length; i++ ) {
			cb[i]( item );
		}
	}
}
