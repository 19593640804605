( function(){
	'use strict';

	var Ten4Slideshow = window.Ten4Slideshow = function( container ){
		this.slides = container.querySelectorAll( '.slideshow__item' );
		this.top_slide_i = 0;
		this.slide_callbacks = [];
		this.slides[this.top_slide_i].classList.add( 'slideshow__item--top' );
		// this.addSlideProp( container );
		// var next = document.getElementById( container.getAttribute( 'data-next' ) );
		// if( next ){
		// 	next.addEventListener( 'click', ( function( slideshow ){
		// 		return function(){
		// 			container.classList.add( 'slideshow--swap' );
		// 			slideshow.slide();
		// 		};
		// 	} )( this ) );
		// }
		// var previous = document.getElementById( container.getAttribute( 'data-previous' ) );
		// if( previous ){
		// 	previous.addEventListener( 'click', ( function( slideshow ){
		// 		return function(){
		// 			container.classList.remove( 'slideshow--swap' );
		// 			slideshow.slide( true );
		// 		};
		// 	} )( this ) );
		// }
	};

	Ten4Slideshow.prototype.slideTo = function( index ){
		if( index < 0 || index > ( this.slides.length - 1 ) ){
			return;
		}
		for( var i = 0; i < this.slides.length; i += 1 ){
			this.slides[i].classList.remove( 'slideshow__item--behind' );
		}
		for( var j = 0; j < this.slide_callbacks.length; j += 1 ){
			this.slide_callbacks[j]( index );
		}
		// This fixes an issue with classes being applied simultaneously - a 1ms delay prevents it
		window.setTimeout( ( function( slideshow ){
			return function(){
				slideshow.slides[slideshow.top_slide_i].classList.remove( 'slideshow__item--top' );
				slideshow.slides[slideshow.top_slide_i].classList.add( 'slideshow__item--behind' );
				slideshow.slides[index].classList.add( 'slideshow__item--top' );
				slideshow.top_slide_i = index;
			};
		} )( this ), 1 );
	};

	Ten4Slideshow.prototype.slide = function( backwards ){
		var next_slide_i = this.top_slide_i + ( backwards ? -1 : 1 );
		if( next_slide_i < 0 ){
			next_slide_i = this.slides.length - 1;
		}
		else if( next_slide_i > ( this.slides.length - 1 ) ){
			next_slide_i = 0;
		}
		this.slideTo( next_slide_i );
	};

	// Ten4Slideshow.prototype.addSlideProp = function( container ){
	// 	var slideshow_prop = this.getTallestSlide().cloneNode( true );
	// 	slideshow_prop.classList.remove( 'slideshow__item' );
	// 	slideshow_prop.classList.add( 'slideshow__prop' );
	// 	container.appendChild( slideshow_prop );
	// };

	// Ten4Slideshow.prototype.getTallestSlide = function(){
	// 	var tallest = this.slides[0];
	// 	for( var i = 1; i < this.slides.length; i += 1 ){
	// 		if( this.slides[i].clientHeight > tallest.clientHeight ){
	// 			tallest = this.slides[i];
	// 		}
	// 	}
	// 	return tallest;
	// };

	Ten4Slideshow.prototype.addCallback = function( func ){
		this.slide_callbacks.push( func );
	};

} )();
